import { queryUnReadMessageCount } from '../apis';

const state = {
  currentUser:{},
  userUnreadMessageCount:0
};
const getters = {
  getCurrentUser: (state) => state.currentUser,
  getUserUnreadMessageCount: (state) => state.userUnreadMessageCount
};
const mutations = {
  setCurrentUser(state, preload) {
    state.currentUser = { ...state.currentUser, ...preload };
  },
  resetCurrentUser(state) {
    state.currentUser = {};
  },

  setUnreadMessageCount(state, preload) {
    state.userUnreadMessageCount = preload;
  }
};
const actions = {
  /**
   * 系统未读消息数量，非常通用，所以单独抽取到vuex中
   * @param commit
   */
  queryUserUnreadMessage({ commit }) {
    queryUnReadMessageCount(null, { showLoading:false }).then((res) => {
      commit('setUnreadMessageCount', res || 0);
    });
  }

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
