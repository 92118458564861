import Vue from 'vue';
import moment from 'moment';
import get from 'lodash/get';
import config, { days, TIMERANGE } from '../constants/contants';

/**
 * view层日期格式化fitler
 * type 格式化参数 、、 http://momentjs.cn/docs/#/i18n/ 这里引用Moment.js的格式化
 * YYYY-MM-DD hh:mm:ss 年月日时分秒
 */
Vue.filter('dateFilter', (data, patterns = 'YYYY-MM-DD HH:mm:ss') => {
  if (!data) return '';
  return moment(window.parseInt(data)).format(patterns);
});
// 将如期字符串转成时间戳
Vue.filter('toDateStamp', (data) => (data ? moment(data).valueOf() : ''));
// 将10 格式化成10.00
Vue.filter('moneyFormatter', (data, params = {
  defaultValue: '0.00'
}) => {
  if (!data) return params.defaultValue;
  return parseFloat(data).toFixed(2);
});
// 将日期转换成年龄
Vue.filter('birthdayToAge', (data, patterns = new Date()) => {
  if (!data) return 1;
  const months = moment(patterns).diff(moment(data), 'months', true);
  const birthSpan = { year: Math.floor(months / 12), month: Math.floor(months) % 12 };
  return `${birthSpan.year}`;
});

// 等同于json.stringify
Vue.filter('stringify', (data) => JSON.stringify(data));

Vue.filter('toString', (data) => data?.toString());

// gender 转为性别
Vue.filter('genderToSex', (data) => config.sex[data]);
// view里面根据path 取constants对应的值
Vue.filter('mapObject', (path, defaultValue = '') => get(config, path, defaultValue));
// view 中 转上午下午晚上--- 如果没匹配上就显示value值
Vue.filter('timeFilter', (value) => TIMERANGE[value] || value);
// 时间距离今天的时间差异 参考http://momentjs.cn/docs/#/displaying/difference/
Vue.filter('timeBetween', (data, patterns = 'days') => moment().diff(moment(data), patterns));
// 计算日期的星期数，并格式化
Vue.filter('dateToDays', (data) => days[moment(data).format('E') - 1]);

Vue.filter('nameReplace', (data, patterns = '*') => (data ? data.substr(0, 1).padEnd(data.length, patterns) : ''));
// 隐藏手机号中间四位
Vue.filter('toPhoneHide', (phone) => phone && `${phone.substr(0, 3)}****${phone.substr(7)}`);

Vue.filter('idCardReplace', (data) => (data ? data.replace(/^(.{4})(?:\d+)(.{4})$/, '$1******$2') : ''));
// 银行卡格式化 1234 1234 1234 123
Vue.filter('formatBank', (data) => data && data.replace(/(\d{4})(?=\d)/g, '$1 '));
// 银行卡**** **** 格式化
Vue.filter('hiddenBank', (data) => data && data.replace(/(\d{4}\s)/g, '**** '));
