import Vue from 'vue';
import * as ui from 'vant';
import YjEmpty from '../components/YjEmpty';

Vue.use(ui.Row);
Vue.use(ui.Col);
Vue.use(ui.Button);
Vue.use(ui.Cell);
Vue.use(ui.CellGroup);
Vue.use(ui.Icon);
Vue.use(ui.Grid);
Vue.use(ui.GridItem);
Vue.use(ui.Image);
Vue.use(ui.Toast);
Vue.use(ui.Panel);
Vue.use(ui.Empty);
Vue.use(ui.Dialog);
Vue.use(ui.Divider);
Vue.use(ui.NavBar);
Vue.use(YjEmpty.name, YjEmpty);
ui.Toast.setDefaultOptions('loading', {
  duration: 500000,
  forbidClick: true,
  loadingType: 'spinner'
});
Vue.prototype.$alert = ui.Dialog.alert;
Vue.prototype.$comfirm = ui.Dialog.confirm;
Vue.prototype.$toast = ui.Toast;
Vue.prototype.$loading = ui.Toast.loading;
Vue.prototype.$fail = ui.Toast.fail;
Vue.prototype.$success = ui.Toast.success;
