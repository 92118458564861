const BASEURL = process.env.VUE_APP_REQUESTURL;
export default {
  bankOCR:'/order/baseOrder/bank', // 银行卡识别
  idCardOCR:'/order/baseOrder/idCard ', // 身份证识别
  queryAndroidLasterVersion: '/app/baseAppUpdate/newApp', // 查询android最新版本
  checkUserPhone: '/check/phone', // 校验用户手机号
  sendCodeSms: '/sms/send', // 获取验证码
  login: '/login', // 登录
  queryUserByToken: '/userInfo', // 根据token获取用户信息
  queryAllOrderInPools: '/order/baseOrder/allOrders', // 获取所有订单池订单信息
  orderAssessmentInfo: '/order/baseOrder/assessmentInfo/:orderId', // 订单评估详情
  cancelOrder: '/order/baseOrder/cancelOrder', // 拒绝订单并转单
  assessmentPass: '/order/baseOrder/assessmentPass', // 通过或拒绝评估
  queryAllCompletedOrders: '/order/baseOrder/getCompleOrders', // 获取所有已完成订单
  queryAllExecuteOrders: '/order/baseOrder/getExecutOrders', // 获取所有执行中订单
  queryAllFinishedOrders: '/order/baseOrder/getFinishOrders', // 获取所有完结中订单
  incomeStat: '/order/baseOrder/incomeAnalysis', // 收入分析
  incomeSurvey: '/order/baseOrder/incomeSurvey', // 收入概览
  queryOrderById: '/order/baseOrder/ordertInfo/:orderId', // 根据orderId获取订单详情
  patientStat: '/order/baseOrder/parentAnalysis', // 患者分析
  grabOrder: '/order/baseOrder/robOrder', // 抢单
  fileUpload: '/oss/upload', // 上传图片
  fileUploadMultiple: '/oss/uploadImgs', // 多图上传
  addFeedBack: '/feedback/baseFeedback/add', // 添加意见反馈
  queryAllFeedBack: '/feedback/baseFeedback/getAll', // 获取所有意见反馈
  deleteFeedBack: '/feedback/baseFeedback/delete/:id', // 删除意见
  queryFeedBackById: '/feedback/baseFeedback/info/:id', // 根据id查询意见
  queryAllComment: '/comment/baseComment/all', // 获取所有评论
  queryCommentById: '/comment/baseComment/commentInfo/:id', // 根据id查询评论信息
  replayComment: '/comment/baseComment/reply', // 回复评论
  queryCostByOrderId: '/predictsupply/basePredictSupplyCost/info/:orderId', // 护理类型预估耗材明细
  confirmOrderByNurse: '/nursingprocess/nursingProcess/confirm', // 护士确认上门
  updateConfirmOrderByNurse: 'nursingprocess/nursingProcess/updateConfirm', // 填写核销码时的确认上门
  nursingProcessDetails:'/nursingprocess/nursingProcess/info/:orderId', // 护理进度明细
  submitExamineCode:'/nursingprocess/nursingProcess/examineCode/:orderId/:examineCode', // 护理进度明细
  processFinished:'/nursingprocess/nursingProcess/nurseComplete', // 护士完成护理并填写护理小结
  processStart:'/nursingprocess/nursingProcess/nurseGo/:orderId', // 护士出发
  submitWastesInfo:'/waste/nursingWasteDisposal/add', // 废弃物登记
  getBaseFaqSetInfo:'/faq/baseFaqSet/getBaseFaqSetInfo', // 常见问题列表查询
  getOrderCount: '/order/baseOrder/indexNum', // 订单执行情况，包括进行中的数量、待处理数量、月度已完成数量
  queryAllGoodsInfo: '/goods/baseGoods/goodsInfoAll', // 查询所有耗材
  submitGoodsOrder: '/nursingprocess/nursingProcess/addGoods', // 保存耗材订单
  querySummaryInfo: '/nursingremake/nursingRemake/getRemakeByOrder/:orderId', // 根据订单id查询护理小结信息
  saveSummaryInfo: '/nursingremake/nursingRemake/saveRemake', // 暂存护理小结信息
  robOrder: '/order/baseOrder/robOrder', // 抢单
  abolishGoods: '/order/baseOrder/abolishGoods/:orderId', // 作废耗材订单
  queryCostOrderInfo: '/goods/baseGoods/getGoodsDetil/:goodOrderId', // 查询耗材清单
  queryOrderHasUnpaid: '/goods/baseGoods/checkHasGoods/:orderId', // 查询当前护理订单中是否包含有待支付的耗材订单
  queryOrderHasGoodsInfo : '/goods/baseGoods/checkHasGoodsDetail/:orderId', // 检查当前订单是否有添加耗材
  callAlarm: '/order/baseOrder/nurseCallPlice', // 一键报警
  submitNotContact: '/nursingprocess/nursingProcess/contactNo/:orderId', // 联系不上患者，调用接口
  querySystemMessageByNurseId: '/system/sysMessage/queryNurseSys/:nurseId', // 根据护士id查询当前护士的消息
  querySystemListPage: '/system/sysMessage/querySysMessagePage', // 分页查询系统消息列表
  querySystemMessageDetails: '/system/sysMessage/querySysMessageDetail', // 系统消息消息详情
  queryUnReadMessageCount: '/system/sysMessage/unreadMsgCount', // 获取系统未读消息数量
  updateSystemMessageStatus: '/system/sysMessage/updateSysMessageUserByUserId', // 系统消息一键已读｜未读
  queryUserMessageList: '/system/sysMessage/queryMySysMessageList', // 分页查询消息列表
  queryLastestMessageByNurseId: 'system/sysMessage/queryNurseSys/:nurseId', // 根据护士id查询最新一条消息
  setMessageHasReadByMessageId: '/system/sysMessage/updateSysRead/:messageId', // 根据id将消息设置为已读
  queryAddressInfo: '/nursePosition/getAllAddrs/:orderId/:nurseId', // 根据订单和护士id获取当前护士的路径经纬度信息
  dismissPartOfTotalFee :'/order/baseOrder/refMoneyRoad/:orderId', // 免除路费
  callVirtualPhone: '/privateNumber/getPrivateNumber/:nurserPhone/:patientPhone/:orderId', // 拨打虚拟电话
  queryAllGoodsListByOrderId: '/goods/baseGoods/getGoodsByOrderId/:orderId', // 查询当前订单下的所有耗材
  // -- 护士注册start ---
  checkNurseNotPass: '/getNurseNoPass/:phone', // 护士注册时在登录界面查看是否有未通过的
  checkPhoneRegister: '/check/reg/phone', // 护士注册验证号码
  sendCodeRegister: '/register/sms/send', // 护士注册时发送验证码
  queryOrgListByPhone: '/getOrg/:phone', // /nurse/getOrg/{phone}查询护士注册时能选的机构
  queryDeptListByOrgId: '/getDept/:orgId', // 根据机构号查询当前机构下的科室
  queryServiceTypeByOrgId: '/getServiceProject/:orgId', // 根据机构ID查询当前机构下可用的服务
  nurseRegister: '/register', // 护士注册接口
  cancelReg: '/cancelReg/:phone/:id', // 护士取消注册
  queryNoPassReason: '/noPassReason/:id', // 查询不通过原因
  // 护士注册end
  // -- 视频功能块开始 --
  queryVideoCategoryList: '/video/baseVideoColumn/queryAll', // 查询视频栏目
  collectVideo: '/video/baseMyFollow/collect/:videoId', // 收藏视频
  queryMyCollectVideoPage: '/video/baseMyFollow/getAll/:pageNum/:pageSize', // 分页查询我的收藏
  /**
   * {
        "columnId": 0,
        "freeVideo": "string",
        "hotVideo": "string",
        "keyWord": "string",
        "newVideo": "string",
        "pageNum": 0,
        "pageSize": 0,
        "promotionVideo": "string"
      }
   */
  queryVideoList: '/video/baseVideo/query', // 查询所有视频
  queryVideoDetailsById: '/video/baseVideo/getVideoById/:id', // 根据视频ID查询当前视频详情
  queryVideoCommentList: '/video/baseVideo/getVideoComment/:videoId/:pageNum/:pageSize', // 查询视频评论
  queryVideoOrderPayInfo: '/video/baseVideo/getVideoBuyById/:orderId', // 根据订单号查询当前订单支付信息，
  submitVideoOrderInfo: '/video/baseVideo/buyVideo', // 用户购买视频
  confirmVideoIsPromotion: '/video/baseVideo/getById/:videoId', // 视频促销超时时再次确认是否已经超时
  queryMyVideoOrderListPage: '/video/baseVideo/queryMyVideoOrder', // 分页查询我的视频订单
  addVideoComment: '/video/baseVideo/addVideoComment', // 添加视频评论
  queryMyFollowVideoListPage: '/video/baseMyFollow/getAll/:pageNum/:pageSize', // 分页查询我的视频收藏
  updateVideoPlayCount: '/video/baseVideo/playVideoCount/:videoId', // 增加视频播放量

  // -- 视频功能块结束 --
  // 优惠券功能开始
  queryCouponListPage: '/coupon/baseCouponUser/getAll', // 查询当前护士拥有的所有优惠券
  queryCouponOrderRank: '/coupon/baseCouponUser/getAllOrderAmount/:pageNum/:pageSize/:orgId', // 分页查询下单金额排行榜
  queryCouponRegisterRank: '/coupon/baseCouponUser/getAllShare/:pageNum/:pageSize/:orgId', // 分页查询分享注册排行榜
  queryAllShareRegisterDetails: '/coupon/baseCouponUser/getAllShareInfo', // 推荐注册明细
  queryAllShareOrderDetails: '/coupon/baseCouponUser/getAllShareOrderInfo', // 推荐下单明细
  queryCouponAllTotalInfo: '/coupon/baseCouponUser/getAllShareCount', // 下单和注册明细,按月度统计
  queryCouponDescByCode: '/coupon/baseBizConfig/getBycode', // 获取优惠券推荐时的说明
  // 优惠券功能结束
  // -- 银行卡功能
  queryBoundBankCardByNurseId: '/baseNurseBankCard/queryMyBankCard/:nurseId', // 根据护士ID查询当前护士的银行卡
  switchBoundBankCard: '/baseNurseBankCard/changeBankCard', // 绑定银行卡
  unBoundBankCard: '/baseNurseBankCard/unBunDlingCard', // 解除绑定银行卡
  agreeAgreement: '/agreeSign/:nurseId', // 同意护士协议

  // -- 银行卡功能结束
};
