/*
* 判断是否IOS环境, 包括maxOS
* */

export const isIOSEnv = () => {
  const u = navigator.userAgent;
  const app = navigator.appVersion;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
};
/**
 * 判断是否安卓设备
 * @returns {boolean}
 */
export const isAndroidEnv = () => {
  const u = navigator.userAgent;
  return u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
};

export function isIOS() {
  const isIphone = navigator.userAgent.includes('iPhone');
  const isIpad = navigator.userAgent.includes('iPad');
  return isIphone || isIpad;
}

export const isWechat = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('micromessenger');
};

export const base64ToBlob = (dataurl, filename) => { // 将base64转换为文件
  // const arr = dataurl.split(',');
  // const mime = arr[0].match(/:(.*?);/)[1];
  // const bstr = atob(arr[1]);
  // let n = bstr.length; const
  //   u8arr = new Uint8Array(n);
  // // eslint-disable-next-line no-plusplus
  // while (n--) {
  //   u8arr[n] = bstr.charCodeAt(n);
  // }
  // return new File([u8arr], filename, { type:mime });
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  let blob = null;

  // 解决低版本浏览器没有new Blob()问题
  try {
    blob = new Blob([u8arr], { type: mime });
  } catch (e) {
    // eslint-disable-next-line no-multi-assign
    const BlobBuilder = window.BlobBuilder = (
      window.BlobBuilder
      || window.WebKitBlobBuilder
      || window.MozBlobBuilder
      || window.MSBlobBuilder
    );

    if (e.name === 'TypeError' && BlobBuilder) {
      const builder = new BlobBuilder();
      builder.append(u8arr);
      blob = builder.getBlob(mime);
    }
  }

  return blob;
};

export const base64ToFile = (dataurl, fileName) => {
  const Blob = base64ToBlob(dataurl, fileName);
  Blob.lastModifiedDate = new Date();
  Blob.name = fileName;
  return Blob;
};
