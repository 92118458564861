/**
 * url 参数替换
 * @param url
 * @param params
 * @returns {*}
 */
import { TENCENTMAP } from '../constants/contants';
import { isAndroidEnv, isIOSEnv } from './common';

export default (url, params = null) => {
  if (!params) return url;
  let key;
  for (key in params) {
    url = url.replace(`:${key}`, params[key]);
  }
  return url;
};
// 获取url
export const getUrlParams = (url = window.location.href, params) => {
  const { search } = new URL(url);
  const query = search.substring(1);
  const vars = query.split('&');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === params) { return pair[1]; }
  }
  return '';
};

// export const base64

export const loadMap = () => new Promise((resolve, reject) => {
  if (window.TMap) {
    resolve(window.TMap);
    return;
  }
  const scriptEle = document.createElement('script');
  scriptEle.src = TENCENTMAP;
  scriptEle.id = 'tencentMap';
  scriptEle.onload = (a) => {
    resolve(window.TMap);
  };
  document.body.appendChild(scriptEle);
});
/**
 * 获取状态栏高度
 * @returns {Promise<unknown>}
 */
export const getStatusBarHeight = async () => new Promise((resolve, reject) => {
  try {
    if (isAndroidEnv()) {
      window.base.getStatusBarHeight();
    } else if (isIOSEnv()) {
      window.webkit.messageHandlers.getStatusBarHeight.postMessage({});
    }
  } catch (e) {
    console.log(e);
  }
});
/**
 * 身份证识别操作
 * @param idCard  身份证号
 * @param type    获取类型 1 出生日期 2 性别
 * @returns {string|null|number}
 */
export const idCardAnalysis = (idCard, type) => {
  if (type === 1) {
    // 获取出生日期
    return `${idCard.substring(6, 10)}-${idCard.substring(10, 12)}-${idCard.substring(12, 14)}`;
  } if (type === 2) {
    // 获取性别
    if (window.parseInt(idCard.substr(16, 1)) % 2 === 1) {
      return 1;
    }
    // 女
    return 2;
  }
  return null;
};
