import axios from 'axios';
import { getLocalStorage, removeLocalStorage } from './storage';

axios.defaults.headers.common = {
  'Content-Type': 'application/json;charset=UTF-8',
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
};
axios.defaults.baseURL = process.env.VUE_APP_REQUESTURL;
axios.defaults.timeout = 100000;
axios.interceptors.request.use((config) => {
  const options = config.options || {};
  // options.headers = { ...options.headers || {}, Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MjAwNjQwMTEsInVzZXJhY2NvdW50IjoiMTg3MDE0MTY1MjMifQ.CO5SmqAAz5hmmRZ8O_dvCBCLEq1yZ953DZwCJfKIEaM', ...config.headers };
  options.headers = { ...options.headers || {}, Authorization: getLocalStorage('access_token'), ...config.headers };
  return { ...config, ...options };
},
(error) => Promise.reject(error));
axios.interceptors.response.use(
  (response) => {
    if (response?.data?.code === 200) {
      return response.data.data;
    }
    if (response?.data?.code === 90000002) {
      removeLocalStorage('access_token');
      window.location.href = '/';
    }
    return Promise.reject(response.data);
  },
  (error) => Promise.reject(error)
);

export default axios;
