<template>
  <div>
    <van-button
      type="primary"
      @click="$navigateTo({name:'ajax'})"
    >
      ajax
    </van-button>
    <van-button
      type="primary"
      @click="$navigateTo({name:'filter'})"
    >
      常用filter
    </van-button>
    <input type="file" capture="camera"/>
  </div>
</template>
<script>

export default {
  name: 'Home',
};
</script>
<style lang="less">
.hospital {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  div {
    background: #FFFFFF;
    height: 150px;
    line-height: 150px;
    border-radius: 10px;
    /*margin-bottom: 30px;*/
    padding: 40px;
  }
}
</style>
