import { Toast } from 'vant';
import axios from './request';
import translate from './utils';

const LoadOption = { showLoading: true, message: '加载中', loadingType: 'spinner' };

export function promiseGet(url, params, options = {}) {
  const loading = { ...LoadOption, ...options };
  let toastInstance = null;
  if (loading?.showLoading) {
    toastInstance = Toast.loading(loading);
  }
  return new Promise((resolve, reject) => axios.get(translate(url, params), { params, options })
    .finally(() => toastInstance?.close())
    .then((res) => {
      resolve(res);
    })
    .catch((e) => {
      reject(e);
      Toast(e.msg || '服务器开小差了,请联系管理员');
    }));
}
export function promisePost(url, params, options) {
  const loading = { ...LoadOption, ...options };
  let toastInstance = null;
  if (loading?.showLoading) {
    toastInstance = Toast.loading(loading);
  }
  return new Promise((resolve, reject) => axios.post(translate(url, params), params, options)
    .finally(() => toastInstance?.close())
    .then((res) => {
      resolve(res);
    })
    .catch((e) => {
      reject(e);
      Toast(e.msg || '服务器开小差了,请联系管理员');
    }));
}

export function promiseDelete(url, params, options) {
  const loading = { ...LoadOption, ...options };
  let toastInstance = null;
  if (loading?.showLoading) {
    toastInstance = Toast.loading(loading);
  }
  return new Promise((resolve, reject) => axios.delete(translate(url, params), params, options)
    .finally(() => toastInstance?.close())
    .then((res) => {
      resolve(res);
    })
    .catch((e) => {
      reject(e);
      Toast(e.msg || '服务器开小差了,请联系管理员');
    }));
}

export function promisePut(url, params, options) {
  const loading = { ...LoadOption, ...options };
  let toastInstance = null;
  if (loading?.showLoading) {
    toastInstance = Toast.loading(loading);
  }
  return new Promise((resolve) => axios.put(translate(url, params), params, options)
    .finally(() => toastInstance?.close())
    .then((res) => {
      resolve(res);
    })
    .catch((e) => {
      Toast(e.msg || '服务器开小差了,请联系管理员');
    }));
}

export default {
  promiseDelete, promiseGet, promisePost, promisePut
};
