import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Index from '../views/Index';

Vue.use(VueRouter);

const routes = [
  { path: '/', name: 'Home', component: Home, },
  // { path:'/ajax', name:'ajax', component: () => import('../test/Ajax/Ajax') },
  // { path:'/filter', name:'filter', component: () => import('../test/image/Filter') },
  // {path:'/asdfads', name:'aaaIndex', component:()=>import('adfa'),meta:{requireAuth:true|false,title:'adfadsf',showFooter:false}} ,
  { path:'/nurse',
    name:'nurse',
    component:Index,
    redirect: 'centerView',
    children:[
      // 废弃
      { path:'home', name:'patientHome', component: () => import('../views/home/PatientHome'), meta:{ showFooter:true, title:'我的' } },
      // 我的首页
      { path:'index', name:'patientIndex', component: () => import('../views/home/PatientHome'), meta:{ showFooter:true, title:'首页', requireAuth: true } },
      // 废弃
      { path:'category', name:'categoryIndex', component: () => import('../views/home/PatientHome'), meta:{ showFooter:true, title:'分类' } },
      // 常见问题
      { path:'question', name:'questionIndex', component:() => import('../views/home/Question'), meta: { title:'常见问题', requireAuth: true } },
      // 订单详情
      { path:'orderDetails/:orderId', name:'orderDetails', component:() => import('../views/index/OrderDetails'), meta: { title:'订单详情', requireAuth: true } },
      // 订单详情操作
      { path:'orderOpera/:orderId', name:'acceptOrderDetails', component:() => import('../views/index/AcceptOrder'), meta: { title:'专项评估', requireAuth: true } },
      // 废弃
      { path:'canvas', name:'canvas', component:() => import('../views/category/Canvas'), meta: { title:'订单详情', requireAuth: true } },
      // 我的意见 id 为意见ID ,如果有id则为编辑或查看，没有则是新增
      { path:'suggest/:id?', name:'submitSuggest', component:() => import('../views/home/Suggest'), meta: { title:'提意见', requireAuth: true } },
      // 意见反馈详情
      { path:'suggest/view/:id?', name:'suggestCheckView', component:() => import('../views/home/SuggestView'), meta: { title:'意见详情', requireAuth: true } },
      // 设置
      { path:'setting', name:'settingView', component:() => import('../views/home/Setting'), meta: { title:'设置', requireAuth: true } },
      // 废弃物回收 orderId-> 订单id
      { path:'garbage/:orderId', name:'garbageView', component:() => import('../views/index/Garbage'), meta: { title:'废弃物处置登记', requireAuth: true } },
      // 个人中心
      { path:'center', name:'centerView', component:() => import('../views/home/Center'), meta: { title:'个人中心', showFooter: true, requireAuth: true } },
      // 消息中心
      { path:'message', name:'messageView', component:() => import('../views/home/MyMessage'), meta: { title:'我的消息', showFooter: false, requireAuth: true } },
      // 我的通知
      { path:'notice', name:'noticeView', component:() => import('../views/home/MyNotice'), meta: { title:'系统公告', showFooter: false, requireAuth: true } },
      // 系统消息列表页
      { path:'message/list/index', name:'messageListView', component:() => import('../views/home/MessageList'), meta: { title:'通知消息', showFooter: false, requireAuth: true } },
      // 护理首页
      { path:'cure', name:'cureView', component:() => import('../views/index/CureIndex'), meta: { title:'护理首页', showFooter: true, requireAuth: true } },
      // 评价 需要传递id
      { path:'evaluate/:id', name:'evaluateView', component:() => import('../views/index/Evaluate'), meta: { title:'评价回复', showFooter: false, requireAuth: true } },
      // 登录
      { path:'login', name:'loginView', component:() => import('../views/home/LoginView'), meta: { title:'登录', showFooter: false } },
      // 确认上门时间
      { path:'confirm/:orderId', name:'confirmView', component:() => import('../views/index/ConfirmInfo'), meta: { title:'确认上门时间', showFooter: false, requireAuth: true } },
      // 耗材清单 orderId 当前您需要添加耗材的订单id  query 可能会有costOrderId(耗材订单的id)
      { path:'consumable/list/:orderId', name:'consumableView', component:() => import('../views/index/ConsumableLists'), meta: { title:'耗材清单', showFooter: false, requireAuth: true } },
      // 统计
      { path:'stat/list', name:'statView', component:() => import('../views/index/Statistics'), meta: { title:'我的统计', showFooter: false, requireAuth: true } },
      // 护理小结 orderId -> 当前订单的id
      { path:'summary/index/:orderId', name:'writeCureView', component:() => import('../views/index/CureSummary'), meta: { title:'填写护理小结', showFooter: false, requireAuth: true } },
      // 我的回复
      { path:'evaluate/list/index', name:'evaluateListView', component:() => import('../views/index/EvaluateList'), meta: { title:'我的回复', showFooter: false, requireAuth: true } },
      // 我的意见列表
      { path:'suggest/list/index', name:'suggestView', component:() => import('../views/home/SuggestList'), meta: { title:'我的意见', showFooter: false, requireAuth: true } },
      // 护理进度 orderId->订单id
      { path:'process/index/:orderId', name:'processView', component:() => import('../views/home/Process'), meta: { title:'护理进度', showFooter: false, requireAuth: true } },
      // 添加耗材(废弃)
      { path:'consumable/index', name:'addConsumableView', component:() => import('../views/index/AddConsumable'), meta: { title:'添加耗材', showFooter: false, requireAuth: true } },
      // 用户协议
      { path:'agreement/index', name:'agreementView', component:() => import('../views/home/Agreement'), meta: { title:'隐私协议', showFooter: false, requireAuth: false } },
      // 银行卡列表页面
      { path:'bank/list', name:'bankCardListView', component:() => import('../views/home/BankCardList'), meta: { title:'我的银行卡', showFooter: false, requireAuth: true } },
      // 添加银行卡
      { path:'bank/add', name:'bankCardAddView', component:() => import('../views/home/BankCardAdd'), meta: { title:'添加银行卡', showFooter: false, requireAuth: true } },
      // 护士签约协议
      { path:'agree/page/index', name:'agreePage', component:() => import('../views/category/NurseAgreementPage'), meta: { title:'添加银行卡', showFooter: false, requireAuth: true } },
      // 护士注册验证手机号码
      { path:'register/verify/index', name:'registerVerifyPhone', component:() => import('../views/home/register/RegistVerifyPhoneCode'), meta: { title:'护士注册-验证手机', showFooter: false, requireAuth: false } },
      // 护士注册个人信息 :phoneNumber 必须传递的注册是用到的电话号码
      { path:'register/person/index/:phoneNumber', name:'registerPersonInfo', component:() => import('../views/home/register/RegisterPersonalInfo'), meta: { title:'护士注册-个人信息', showFooter: false, requireAuth: false } },
      // 护士注册结果页
      { path:'register/result/index/:regId/:phone', name:'registerResult', component:() => import('../views/home/register/RegisterResult'), meta: { title:'护士注册-审核中', showFooter: false, requireAuth: false } },
      { path:'register/result/error/:regId/:phone', name:'registerError', component:() => import('../views/home/register/RegisterError'), meta: { title:'护士注册-审核不通过', showFooter: false, requireAuth: false } },
      // 护士注册执业信息
      { path:'register/practice/index/:phoneNumber', name:'registerPracticeInfo', component:() => import('../views/home/register/RegisterPracticeInfo'), meta: { title:'护士注册-执业信息', showFooter: false, requireAuth: false } },
      // 优惠券列表
      { path:'coupon/list/index', name:'couponListView', component:() => import('../views/home/coupon/CouponListPage'), meta: { title:'我的优惠券', showFooter: false, requireAuth: true } },
      // 视频学习主页
      { path:'study/video/index', name:'videoHomeView', component:() => import('../views/video/VideoHomePage'), meta: { title:'视频学习', showFooter: true, requireAuth: true } },
      // 热门好课/免费好课/最新新课 :type: hot|free|news
      { path:'study/:type/video', name:'videoHotView', component:() => import('../views/video/HotVideoPage'), meta: { title:'热门好课', showFooter: false, requireAuth: true } },
      // 限时促销
      { path:'study/promotion/index', name:'videoPromotionView', component:() => import('../views/video/PromotionPage'), meta: { title:'限时促销', showFooter: false, requireAuth: true } },
      // 视频详情 :id -> 视频id
      { path:'study/video/details/:id', name:'videoDetailsView', component:() => import('../views/video/VideoDetailsPage'), meta: { title:'视频介绍', showFooter: false, requireAuth: true } },
      // 视频购买确认订单
      { path:'video/order/confirm/:id', name:'videoOrderConfirmView', component:() => import('../views/video/VideoOrderConfirmPage'), meta: { title:'确认订单', showFooter: false, requireAuth: true } },
      // 支付成功页 id 购买视频生成的订单id
      { path:'video/order/success/:id', name:'videoOrderSuccessView', component:() => import('../views/category/PaidSuccessPage'), meta: { title:'支付成功', showFooter: false, requireAuth: true } },
      // 我的视频页面
      { path:'video/index', name:'myVideoView', component:() => import('../views/video/MyVideoListPage'), meta: { title:'我的视频', showFooter: false, requireAuth: true } },
      // 视频播放 id: 视频id
      { path:'video/play/:id', name:'videoPlayView', component:() => import('../views/video/VideoPlayPage'), meta: { title:'视频播放', showFooter: false, requireAuth: true } },
      // 推荐排行榜页面
      { path:'rank/index', name:'rankView', component:() => import('../views/home/recom/RankingPage'), meta: { title:'排行榜', showFooter: false, requireAuth: true } },
      // 邀请好友活动页面
      { path:'activity/share/index', name:'activityShareView', component:() => import('../views/home/recom/InvitePage'), meta: { title:'推荐用户', showFooter: false, requireAuth: true } },
      // 推荐详情页面
      { path:'rank/details', name:'rankDetailsView', component:() => import('../views/home/recom/RecomPage'), meta: { title:'推荐明细', showFooter: false, requireAuth: true } },
      // 分享二维码链接
      { path:'share/links', name:'linksShareView', component:() => import('../views/home/recom/RecomQrCode'), meta: { title:'推荐用户', showFooter: false, requireAuth: true } },
      // 下载app
      { path:'download/apps/index', name:'downloadView', component:() => import('../views/common/DownAppView'), meta: { title:'下载', showFooter: false, requireAuth: false } },

    ]
  },
  { path:'*', component:() => import('../views/Lost'), meta:{ title:'页面丢失了' } }

];

const router = new VueRouter({
  base:process.env.VUE_PUBLIC_PATH,
  mode: process.env.VUE_APP_ENV === 'production' ? 'hash' : 'history',
  routes,
});

export default router;
