import { mapGetters } from 'vuex';
import get from 'lodash/get';
import StatusContants, { defaultPage } from '../constants/contants';
import { isIOSEnv, isAndroidEnv } from '../utils/common';
import { getStatusBarHeight } from '../utils/utils';

export default {
  computed: {
    $constantArray() { // 增加自定义全部选项 默认全部
      return (path, showAll = false, extra = { all : { text:'全部', value:'' } }) => {
        const jsonObject = StatusContants[path];
        const jsonArray = Object.keys(jsonObject).map((e) => ({ value:e, text:jsonObject[e] }));
        if (showAll) {
          return [extra.all, ...jsonArray];
        }
        return jsonArray;
      };
    },
    $constants:() => (path, defaultValue = {}) => get(StatusContants, path, defaultValue),
    ...mapGetters({
      messageCount: 'User/getUserUnreadMessageCount',
      currentUser: 'User/getCurrentUser',
      statusBar:'Platform/statusBar'
    }),
    $currentOrg() { // 当前选中的orgInfo
      return this.currentUser.currentLoginOrg;
    },
    $query() {
      return this.$route.query || {};
    },
    $params() {
      return this.$route.params || {};
    },
    $isIos() {
      return isIOSEnv();
    },
    $isAndroid() {
      return isAndroidEnv();
    }
  },
  data() {
    return {
      page:defaultPage,
      queryParams:{
      },
    };
  },
  methods:{
    // 开启定位
    $startLocation(orderId, nurseId) {
      return new Promise(((resolve, reject) => {
        try {
          if (isAndroidEnv()) {
            window.map.startLocation(orderId, nurseId);
          } else if (isIOSEnv()) {
            window.webkit.messageHandlers.startLocation.postMessage({ orderId, nurseId, });
          }
          resolve();
        } catch (e) {
          this.$toast('无法调用硬件');
          reject(e);
        }
      }));
    },
    // 暂停定位
    $pauseLocation() {
      return new Promise((resolve, reject) => {
        try {
          if (isAndroidEnv()) {
            window.map.pauseLocation();
          } else if (isAndroidEnv()) {
            window.webkit.messageHandlers.pauseLocation.postMessage({});
          }
          resolve();
        } catch (e) {
          this.$toast('无法调用硬件');
          reject(e);
        }
      });
    },
    // 重启定位
    $resumeLocation() {
      return new Promise((resolve, reject) => {
        try {
          if (isAndroidEnv()) {
            window.map.resumeLocation();
          } else if (isIOSEnv()) {
            window.webkit.messageHandlers.resumeLocation.postMessage({});
          }
          resolve();
        } catch (e) {
          this.$toast('无法调用硬件');
          reject(e);
        }
      });
    },
    // 停止定位
    $stopLocation({ orderId, nurseId }) {
      return new Promise((resolve, reject) => {
        try {
          if (isAndroidEnv()) {
            window.map.stopLocation(orderId, nurseId);
          } else if (isIOSEnv()) {
            window.webkit.messageHandlers.stopLocation.postMessage({ orderId, nurseId });
          }
          resolve();
        } catch (e) {
          this.$toast('无法调用硬件');
          reject(e);
        }
      });
    },
    // 获取当前地理位置
    $currentLocation() {
      return new Promise((resolve, reject) => {
        try {
          if (isAndroidEnv()) {
            window.map.currentLocation();
          } else if (isIOSEnv()) {
            window.webkit.messageHandlers.currentLocation.postMessage({});
          }
          resolve();
        } catch (e) {
          this.$toast('无法调用硬件');
          reject(e);
        }
      });
    },
    // 显示地图（选点）
    $showMap() {
      return new Promise((resolve, reject) => {
        try {
          if (isAndroidEnv()) {
            window.map.showMap();
          } else if (isIOSEnv()) {
            window.webkit.messageHandlers.showMap.postMessage({});
          }
          resolve();
        } catch (e) {
          this.$toast('无法调用硬件');
          reject(e);
        }
      });
    },
    /**
     * 开启录音
     * @param orderId 订单id
     * @param nurseId 护士id
     * @param orgId   机构id
     * @returns {Promise<unknown>}
     */
    $startRecord({ orderId, nurseId, orgId }) {
      return new Promise((resolve, reject) => {
        try {
          if (isAndroidEnv()) {
            window.recorder.startRecord(orderId, nurseId, orgId);
          } else if (isIOSEnv()) {
            window.webkit.messageHandlers.startRecord.postMessage({ orderId, nurseId, orgId });
          }
          resolve();
        } catch (e) {
          this.$toast('无法调用硬件');
          reject(e);
        }
      });
    },
    // 结束录音并上传
    $stopRecord({ orderId, nurseId, orgId }) {
      return new Promise((resolve, reject) => {
        console.log('结束录音并上传', '--------->');
        try {
          if (isAndroidEnv()) {
            window.recorder.stopRecord(orderId, nurseId, orgId);
          } else if (isIOSEnv()) {
            window.webkit.messageHandlers.stopRecord.postMessage({ orderId, nurseId, orgId });
          }
          resolve();
        } catch (e) {
          this.$toast('无法调用硬件');
          reject(e);
        }
      });
    },
    /**
     * 调用原生保存图片（网络图片）
     * @param url
     * @returns {Promise<unknown>}
     */
    $callNativeDownloadImage({ url }) {
      return new Promise((resolve, reject) => {
        try {
          if (isAndroidEnv()) {
            window.base.savePic(url);
          } else if (isIOSEnv()) {
            window.webkit.messageHandlers.savePic.postMessage({ url });
          }
          resolve();
        } catch (e) {
          this.$toast('无法调用硬件');
          reject(e);
        }
      });
    },
    // 查询录音是不是没上传完成
    $recordFinished({ orderId, nurseId }) {
      return new Promise((resolve, reject) => {
        try {
          if (isAndroidEnv()) {
            window.recorder.recordFinished(orderId, nurseId);
          } else if (isIOSEnv()) {
            window.webkit.messageHandlers.recordFinished.postMessage({ orderId, nurseId, });
          }
          resolve();
        } catch (e) {
          this.$toast('无法调用硬件');
          reject(e);
        }
      });
    },
    // 开始上传
    $startUpload({ orderId, nurseId }) {
      return new Promise((resolve, reject) => {
        try {
          if (isAndroidEnv()) {
            window.recorder.startUpload(orderId, nurseId);
          } else if (isIOSEnv()) {
            window.webkit.messageHandlers.startUpload.postMessage({ orderId, nurseId, });
          }
          resolve();
        } catch (e) {
          this.$toast('无法调用硬件');
          reject(e);
        }
      });
    },
    // 传递token给app端
    $setToken(token) {
      return new Promise((resolve, reject) => {
        try {
          if (isAndroidEnv()) {
            window.base.setToken(token);
          } else if (isIOSEnv()) {
            window.webkit.messageHandlers.setToken.postMessage({ token });
          }
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    },
    // // 获取原生返回的token 用不上
    // $getToken() {
    //   try {
    //     if (isAndroidEnv()) {
    //       window.map.currentLocation();
    //     } else if (isIOSEnv()) {
    //       window.webkit.messageHandlers.currentLocation.postMessage({});
    //     }
    //   } catch (e) {
    //     this.$toast('无法调用硬件');
    //   }
    // },
    // 设置主题，深色和浅色
    $setStatusBarDarkMode(dark) {
      return new Promise((resolve, reject) => {
        try {
          if (isAndroidEnv()) {
            window.base.setStatusBarDarkMode(dark);
          } else if (isIOSEnv()) {
            window.webkit.messageHandlers.setStatusBarDarkMode.postMessage({ dark });
          }
          resolve();
        } catch (e) {
          this.$toast('无法调用硬件');
          reject(e);
        }
      });
    },
    /**
     * 获取状态栏高度
     */
    $getStatusBarHeight() {
      return getStatusBarHeight();
    },
    $setDocumentTitle(title = '互联网医院') {
      document.title = title;
    },
    $goto(params) {
      this.$router.go(params);
    },
    // 路由跳转
    $navigateTo(params) {
      this.$router.push(params);
    },
    // 不记录浏览器历史
    $replaceTo(params) {
      this.$router.replace(params);
    },
    // 跳转并带上页面的query信息
    $navigateWithQuery(params, query = {}) {
      this.$router.push({ ...params, query:{ ...this.$query, ...query } });
    },
    // 重置分页条件
    $resetPage(cb) {
      const { page } = this;
      this.page = {
        ...page,
        pageNum:1,
      };
      return new Promise((resolve, reject) => resolve({ ...this.page, ...this.queryParams }));
    },
    // 分页加载信息
    $nextPage() {
      const { page } = this;
      this.page = {
        ...page,
        pageNum:page.pageNum + 1
      };
      return new Promise((resolve, reject) => resolve({ ...this.page, ...this.queryParams }));
    },
    // 设置查询条件
    $setQueryParams(params) {
      const { queryParams } = this;
      this.queryParams = {
        ...queryParams,
        ...params
      };
      return new Promise((resolve, reject) => resolve({ ...this.page, ...this.queryParams }));
    },
    // 设置分页参数
    $setPage(params, cb) {
      this.page = {
        ...this.page,
        ...params
      };
      if (cb && typeof cb === 'function') {
        cb({ ...this.page, ...this.queryParams });
      }
    },

    // 这个东西非常好使 ， 参考lodash/get ，
    // 根据 object对象的path路径获取值。 如果解析 value 是 undefined 会以 defaultValue 取代。
    $get:(data, path, defaultValue = '') => get(data, path, defaultValue),
    // 上一页
    $prePage() {
      const { page } = this;
      this.page = {
        ...page,
        pageNum:page.pageNum === 1 ? 1 : page.pageNum - 1
      };
      return new Promise((resolve, reject) => resolve({ ...this.page, ...this.queryParams }));
    }
  }
};
