import { promiseGet, promisePost, promisePut, promiseDelete } from '../utils/promise';
import urls from './urls';

export const checkUserPhone = async (params, options) => promisePost(urls.checkUserPhone, params, options); // 校验用户手机号
export const sendCodeSms = async (params, options) => promisePost(urls.sendCodeSms, params, options); // 获取验证码
export const login = async (params, options) => promisePost(urls.login, params, options); // 登录
export const queryUserByToken = async (params, options) => promisePost(urls.queryUserByToken, params, options); // 根据token获取用户信息
export const queryAllOrderInPools = async (params, options) => promisePost(urls.queryAllOrderInPools, params, options); // 获取所有订单池订单信息
export const orderAssessmentInfo = async (params, options) => promiseGet(urls.orderAssessmentInfo, params, options); // 订单评估详情
export const queryAndroidLasterVersion = async (params, options) => promiseGet(urls.queryAndroidLasterVersion, params, options); // 查询安卓最新版本
export const assessmentPass = async (params, options) => promisePost(urls.assessmentPass, params, options); // 通过或拒绝评估
export const queryAllCompletedOrders = async (params, options) => promisePost(urls.queryAllCompletedOrders, params, options); // 获取所有已完成订单
export const queryAllExecuteOrders = async (params, options) => promisePost(urls.queryAllExecuteOrders, params, options); // 获取所有执行中订单
export const queryAllFinishedOrders = async (params, options) => promisePost(urls.queryAllFinishedOrders, params, options); // 获取所有完结中订单
export const queryIncomeStat = async (params, options) => promisePost(urls.incomeStat, params, options); // 收入分析
export const incomeSurvey = async (params, options) => promisePost(urls.incomeSurvey, params, options); // 收入概览
export const queryOrderById = async (params, options) => promiseGet(urls.queryOrderById, params, options); // 根据orderId获取订单详情
export const patientStat = async (params, options) => promisePost(urls.patientStat, params, options); // 患者分析
export const grabOrder = async (params, options) => promisePost(urls.grabOrder, params, options); // 抢单
export const fileUpload = async (params, options) => promisePost(urls.fileUpload, params, options); // 上传图片
export const fileUploadMultiple = async (params, options) => promisePost(urls.fileUploadMultiple, params, options); // 批量上传
export const addFeedBack = async (params, options) => promisePost(urls.addFeedBack, params, options); // 添加意见反馈
export const queryAllFeedBack = async (params, options) => promisePost(urls.queryAllFeedBack, params, options); // 获取所有意见反馈
export const queryAllComment = async (params, options) => promisePost(urls.queryAllComment, params, options); // 获取所有评论
export const replayComment = async (params, options) => promisePost(urls.replayComment, params, options); // 回复评论
export const queryCostByOrderId = async (params, options) => promiseGet(urls.queryCostByOrderId, params, options); // 护理类型预估耗材明细
export const queryCommentById = async (params, options) => promiseGet(urls.queryCommentById, params, options); // 根据id查询评论信息
export const confirmOrderByNurse = async (params, options) => promisePost(urls.confirmOrderByNurse, params, options); // 护士确认上门
export const updateConfirmOrderByNurse = async (params, options) => promisePost(urls.updateConfirmOrderByNurse, params, options); // 填写核销码时的确认上门
export const nursingProcessDetails = async (params, options) => promiseGet(urls.nursingProcessDetails, params, options); // 护理进度明细
export const processStart = async (params, options) => promisePost(urls.processStart, params, options); // 护士出发
export const submitExamineCode = async (params, options) => promiseGet(urls.submitExamineCode, params, options); // 提交护士核销码核销
export const processFinished = async (params, options) => promisePost(urls.processFinished, params, options); // 护士完成护理并填写护理小结
export const submitWastesInfo = async (params, options) => promisePost(urls.submitWastesInfo, params, options); // 废弃物登记
export const getBaseFaqSetInfo = async (params, options) => promisePost(urls.getBaseFaqSetInfo, params, options); // 常见问题查询
export const cancelOrder = async (params, options) => promisePost(urls.cancelOrder, params, options); // 拒绝订单并转单
export const getOrderCount = async (params, options) => promisePost(urls.getOrderCount, params, options); //  订单执行情况，包括进行中的数量、待处理数量、月度已完成数量
export const submitGoodsOrder = async (params, options) => promisePost(urls.submitGoodsOrder, params, options); //  提交耗材订单
export const queryFeedBackById = async (params, options) => promiseGet(urls.queryFeedBackById, params, options); // 根据ID查询意见
export const deleteFeedBackById = async (params, options) => promiseGet(urls.deleteFeedBack, params, options); // 根据ID删除意见
export const queryAllGoodsInfo = async (params, options) => promiseGet(urls.queryAllGoodsInfo, params, options); // 获取所有耗材
export const querySummaryInfo = async (params, options) => promiseGet(urls.querySummaryInfo, params, options); // 根据订单id获取护理小结信息
export const queryCostOrderDetailsInfo = async (params, options) => promiseGet(urls.queryCostOrderInfo, params, options); // 根据耗材订单id查询所有耗材信息
export const abolishGoods = async (params, options) => promiseGet(urls.abolishGoods, params, options); // 作废耗材订单
export const queryOrderHasUnpaid = async (params, options) => promiseGet(urls.queryOrderHasUnpaid, params, options); // 查询当前护理订单中是否包含有待支付的耗材订单
export const queryOrderHasGoodsInfo = async (params, options) => promiseGet(urls.queryOrderHasGoodsInfo, params, options); // 检查当前订单是否有添加耗材
export const submitNotContact = async (params, options) => promiseGet(urls.submitNotContact, params, options); // 联系不上患者
export const querySystemMessageByNurseId = async (params, options) => promiseGet(urls.querySystemMessageByNurseId, params, options); // 根据护士id查询当前护士的消息
export const querySystemListPage = async (params, options) => promiseGet(urls.querySystemListPage, params, options); // 分页查询系统消息列表
export const queryUnReadMessageCount = async (params, options) => promiseGet(urls.queryUnReadMessageCount, params, options); // 获取系统未读消息数量
export const queryUserMessageList = async (params, options) => promiseGet(urls.queryUserMessageList, params, options); // 分页查询消息列表
export const queryLastestMessageByNurseId = async (params, options) => promiseGet(urls.queryLastestMessageByNurseId, params, options); // 根据护士id查询最新一条消息
export const setMessageHasReadByMessageId = async (params, options) => promiseGet(urls.setMessageHasReadByMessageId, params, options); // 根据id将消息设置为已读
export const queryAddressInfo = async (params, options) => promiseGet(urls.queryAddressInfo, params, options); // 根据订单和护士id获取当前护士的路径经纬度信息
export const dismissPartOfTotalFee = async (params, options) => promiseGet(urls.dismissPartOfTotalFee, params, options); // 免除路费
export const callVirtualPhone = async (params, options) => promiseGet(urls.callVirtualPhone, params, options); // 拨打虚拟电话
export const queryAllGoodsListByOrderId = async (params, options) => promiseGet(urls.queryAllGoodsListByOrderId, params, options); // 查询当前订单下的所有耗材
export const saveSummaryInfo = async (params, options) => promisePost(urls.saveSummaryInfo, params, options); // 暂存护理小结信息
export const submitRobOrderInfo = async (params, options) => promisePost(urls.robOrder, params, options); // 提交抢单请求
export const callAlarm = async (params, options) => promisePost(urls.callAlarm, params, options); // 一键报警
export const updateSystemMessageStatus = async (params, options) => promisePost(urls.updateSystemMessageStatus, params, options); // 系统消息一键已读｜未读
// 护士注册
export const uploadBankCardOCR = async (params, options) => promisePost(urls.bankOCR, params, options); // 银行卡识别
export const uploadIdCardOCR = async (params, options) => promisePost(urls.idCardOCR, params, options); // 身份证识别
export const checkPhoneRegister = async (params, options) => promisePost(urls.checkPhoneRegister, params, options); // 护士注册校验手机
export const sendCodeRegister = async (params, options) => promisePost(urls.sendCodeRegister, params, options); // 护士注册发送验证码
export const nurseRegisterSubmit = async (params, options) => promisePost(urls.nurseRegister, params, options); // 护士注册接口
export const queryOrgListByPhone = async (params, options) => promiseGet(urls.queryOrgListByPhone, params, options); // 查询护士注册时能选的机构
export const queryDeptListByOrgId = async (params, options) => promiseGet(urls.queryDeptListByOrgId, params, options); // 根据机构号查询当前机构下的科室
export const queryServiceTypeByOrgId = async (params, options) => promiseGet(urls.queryServiceTypeByOrgId, params, options); // 根据机构ID查询当前机构下可用的服务
export const checkNurseNotPass = async (params, options) => promiseGet(urls.checkNurseNotPass, params, options); // 护士注册时在登录界面查看是否有未通过的
export const cancelReg = async (params, options) => promiseGet(urls.cancelReg, params, options); // 护士取消注册
export const queryNoPassReason = async (params, options) => promiseGet(urls.queryNoPassReason, params, options); // 查询不通过原因
// 护士注册
// -- 视频功能块开始 --
export const queryVideoCategoryList = async (params, options) => promiseGet(urls.queryVideoCategoryList, params, options); // 查询视频栏目
export const agreeAgreement = async (params, options) => promiseGet(urls.agreeAgreement, params, options); // 同意护士协议
export const collectVideo = async (params, options) => promiseGet(urls.collectVideo, params, options); // 收藏视频
export const queryMyCollectVideoPage = async (params, options) => promiseGet(urls.queryMyCollectVideoPage, params, options); // 分页查询我的收藏
export const queryVideoDetailsById = async (params, options) => promiseGet(urls.queryVideoDetailsById, params, options); // 分页查询我的收藏
export const updateVideoPlayCount = async (params, options) => promiseGet(urls.updateVideoPlayCount, params, options); // 增加视频播放量
export const queryVideoCommentList = async (params, options) => promiseGet(urls.queryVideoCommentList, params, options); // 分页查询视频评论
export const confirmVideoIsPromotion = async (params, options) => promiseGet(urls.confirmVideoIsPromotion, params, options); // 视频促销超时时再次确认是否已经超时
export const queryVideoOrderPayInfo = async (params, options) => promiseGet(urls.queryVideoOrderPayInfo, params, options); // 根据订单号查询当前订单支付信息
export const queryVideoList = async (params, options) => promisePost(urls.queryVideoList, params, options); // 查询视频评论
export const submitVideoOrderInfo = async (params, options) => promisePost(urls.submitVideoOrderInfo, params, options); // 用户购买视频
export const addVideoComment = async (params, options) => promisePost(urls.addVideoComment, params, options); // 添加视频评论
export const queryMyFollowVideoListPage = async (params, options) => promiseGet(urls.queryMyFollowVideoListPage, params, options); // 分页查询我的视频收藏
export const queryMyVideoOrderListPage = async (params, options) => promisePost(urls.queryMyVideoOrderListPage, params, options); // 分页查询我的视频订单
// -- 视频功能块结束 --
// -- 优惠券开始 --
export const queryCouponListPage = async (params, options) => promisePost(urls.queryCouponListPage, params, options); // 查询当前拥有的优惠券
export const queryCouponAllTotalInfo = async (params, options) => promisePost(urls.queryCouponAllTotalInfo, params, options); // 下单和注册明细,按月度统计
export const queryAllShareRegisterDetails = async (params, options) => promisePost(urls.queryAllShareRegisterDetails, params, options); // 推荐下单明细
export const queryAllShareOrderDetails = async (params, options) => promisePost(urls.queryAllShareOrderDetails, params, options); // 推荐下单明细
export const queryCouponOrderRank = async (params, options) => promiseGet(urls.queryCouponOrderRank, params, options); // 分页查询下单金额排行榜
export const queryCouponRegisterRank = async (params, options) => promiseGet(urls.queryCouponRegisterRank, params, options); // 分页查询分享注册排行榜
export const queryCouponDescByCode = async (params, options) => promisePost(urls.queryCouponDescByCode, params, options); // 获取优惠券推荐时的说明
// -- 优惠券结束 --
// -- 银行卡start
export const queryBoundBankCardByNurseId = async (params, options) => promiseGet(urls.queryBoundBankCardByNurseId, params, options); // 根据护士ID查询当前护士的银行卡
export const switchBoundBankCard = async (params, options) => promisePost(urls.switchBoundBankCard, params, options); // 绑定银行卡
export const unBoundBankCard = async (params, options) => promisePost(urls.unBoundBankCard, params, options); // 解除绑定银行卡
// -- 银行卡end

export default {
  cancelOrder,
  addFeedBack,
  queryAllFeedBack,
  queryAllComment,
  replayComment,
  queryCostByOrderId,
  confirmOrderByNurse,
  nursingProcessDetails,
  processStart,
  submitExamineCode,
  processFinished,
  submitWastesInfo,
  orderAssessmentInfo,
  assessmentPass,
  incomeSurvey,
  patientStat,
  grabOrder,
  fileUpload,
  checkUserPhone,
  sendCodeSms,
  login,
  queryUserByToken,
  queryAllCompletedOrders,
  queryAllExecuteOrders,
  queryAllFinishedOrders,
  queryAllOrderInPools,
  queryIncomeStat,
  queryOrderById
};
