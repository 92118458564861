<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  methods:{
  }
};
</script>
<style lang="less">
</style>
