export const setLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.log(e);
  }
};
export const getLocalStorage = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    return null;
  }
};
export const setSessionStorage = (key, value) => sessionStorage.setItem(key, JSON.stringify(value));
export const getSessionStorage = (key) => {
  try {
    return JSON.parse(sessionStorage.getItem((key)));
  } catch (e) {
    return null;
  }
};
export const removeLocalStorage = (key) => (key ? localStorage.removeItem(key) : localStorage.clear());
export const removeSessionStorage = (key) => (key ? sessionStorage.removeItem(key) : sessionStorage.clear());
const storage = {
  setLocalStorage,
  getLocalStorage,
  setSessionStorage,
  getSessionStorage,
  removeLocalStorage,
  removeSessionStorage,
};
window.storage = storage;
export default storage;
