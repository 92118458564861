<template>
  <div class="flex_center padding_all_16">
    {{text || '暂无数据'}}
  </div>
</template>
<script>
export default {
  name: 'YjEmpty',
  props: {
    text: {
      type: String,
      default: ''
    }
  }
};
</script>
<style lang="less" scoped>
</style>
