import router from './router-config';
import { getLocalStorage } from '../utils/storage';
import { queryUserByToken } from '../apis';
import store from '../store';

router.beforeEach((to, from, next) => {
  const token = getLocalStorage('access_token');
  const currentSelectedOrg = getLocalStorage('currentSelectedOrg'); //
  if (to.meta?.requireAuth) { // 通常表示用户是否登录
    if (!token) {
      next({ name:'loginView' });
    } else if (store.state?.User?.currentUser?.id) {
      store.commit('User/setCurrentUser', {
        currentLoginOrgId:currentSelectedOrg
      });
      next();
    } else {
      queryUserByToken({ orgId:currentSelectedOrg.orgId }).then((res) => {
        const currentUser = {
          ...res,
          currentLoginOrg:currentSelectedOrg
        };
        store.commit('User/setCurrentUser', currentUser);
        next();
      }).catch((e) => {
        next({ name:'loginView' });
      });
    }
  } else {
    next();
  }
});
router.afterEach((to) => {
  if (['loginView', 'cureView'].includes(to.name)) { // 首页和登录不显示导航
    store.commit('Platform/hideStatusBar');
  } else {
    store.commit('Platform/setStatusBar', { title:to?.meta?.title });
  }

  document.title = to?.meta?.title;
});

export default router;
