import Vue from 'vue';
import Vconsole from 'vconsole';
import App from './App.vue';
import router from './router';
import store from './store';
import 'normalize.css';
import 'nprogress/nprogress.css';
import './constants/vant.init';
import './plugins/filters';
import 'vant/lib/icon/local.css';
import './assets/fonts/iconfont.css';
import './assets/variable.less';
import mixins from './plugins/minxins';
import { PHONEREG } from './constants/contants';
import { isAndroidEnv, isIOSEnv } from './utils/common';
import { getStatusBarHeight } from './utils/utils';

Vue.prototype.PHONEREG = PHONEREG;
Vue.prototype.defaultUserPhoto = require('./assets/img/default_user_profile.png');

Vue.mixin(mixins);
Vue.config.productionTip = false;
if (process.env.VUE_APP_ENV === 'test' || process.env.VUE_APP_ENV === 'dev') {
  const vConsole = new Vconsole();
  Vue.use(vConsole);
} else {
  console.log = () => {};
  console.dir = () => {};
  console.error = () => {};
  console.table = () => {};
}
try {
  if (isAndroidEnv()) {
    window.base.setStatusBarDarkMode(true);
  } else if (isIOSEnv()) {
    window.webkit.messageHandlers.setStatusBarDarkMode.postMessage({ dark: true });
  }
// eslint-disable-next-line no-empty
} catch (e) {
}

window.getStatusBarHeightCallBack = (height) => {
  store.commit('Platform/setStatusBarHeight', `${(height || 25) + window.devicePixelRatio || 2}px`);
};

getStatusBarHeight();
window.store = store;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
