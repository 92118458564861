import moment from 'moment';

export const TENCENTMAP = 'https://map.qq.com/api/gljs?v=1.exp&key=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77';
export const defaultPage = { pageNum:0, pageSize: 20 }; // 默认分页参数
export const days = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
export const PHONEREG = /^1[3456789]\d{9}/; // 手机号码正则
export const BASE64IMAGEHEADERREG = /^data:image\/\w+;base64,/; // base64图片文件头正则表达式
export const IDCARDREG = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/; // 身份证验证的正则表达式
export const DATERANGE = [{ text: '近7天', value: 0 },
  { text: '近一个月', value: 1 },
  { text: '近半年', value: 2 },
  { text: '近一年', value: 3 },
  { text: '不限', value: 4 }]; // 通用日期段选择范围
export const DATEMAPPER = { // 日期时间段选择对于的起止时间
  0:{ startTime:`${moment().subtract(7, 'days').format('YYYY-MM-DD')} 00:00:00`,
    endTime: `${moment().format('YYYY-MM-DD')} 23:59:59` },
  1:{ startTime:`${moment().subtract(1, 'months').format('YYYY-MM-DD')} 00:00:00`,
    endTime: `${moment().format('YYYY-MM-DD')} 23:59:59` },
  2:{ startTime:`${moment().subtract(6, 'months').format('YYYY-MM-DD')} 00:00:00`,
    endTime: `${moment().format('YYYY-MM-DD')} 23:59:59` },
  3:{ startTime:`${moment().subtract(1, 'year').format('YYYY-MM-DD')} 00:00:00`,
    endTime: `${moment().format('YYYY-MM-DD')} 23:59:59` },
  4:{ startTime:null, endTime: null },
};
export const NURSETITLELEVEL = { 1:'护士', 2:'护师', 3: '主管护师', 4: '副主任护师', 5: '主任护师' }; // 护士级别

// 那就 意见反馈  opinionType   1.投诉  2.上门时效  3.咨询 4.服务收费
// 5.服务态度  6.专业能力  7.界面操作  8.其他  9系统bug  10系统卡顿 11管理问题  12流程问题
export const SUGGESTTAG = { 1:'系统bug', 3:'界面操作', 6:'其他', 4:'管理问题', 2:'系统卡顿', 5: '流程问题' };
/**
 * 从startNumber 开始生成连续的length个数字
 * @param length
 * @param startNumber
 * @returns {unknown[]}
 */
const generatorNumber = (length, startNumber) => Array.from(Array(length), (e, index) => index + startNumber);
// 体温常数
export const temperatureConst = { left:generatorNumber(21, 30), right: generatorNumber(10, 0) };
// 血压常数
export const bloodPressure = { low: generatorNumber(121, 20), high: generatorNumber(201, 60) };
export const pulse = generatorNumber(231, 30); // 脉搏常数
// 血糖常数
export const bloodSugar = { left:generatorNumber(51, 0), right: generatorNumber(10, 0) };

// 腾讯人脸识别url
export const faceVerifyUrl = ({ orderId, redirectUrl }) => `https://health.tengmed.com/open/facePictureVerify?order_id=${orderId}&redirect_uri=${redirectUrl}`;
// 年龄段对应于统计处的字端
export const AGEFIELD = { field1:'0-10岁', field2:'11-20岁', field3:'21-30岁', field4:'30-40岁', field5:'41-50岁', field6:'51-60岁', field7:'61-70岁', field8:'71-80岁', field9:'80岁以上' };
export const TIMERANGE = { 0:'上午', 1:'下午', 2: '晚上' };
export const TIMEDETAILS = { 0:'08:00', 1: '14:30', 2: '19:00' }; // 上午下午对应的具体时间值， 目前用在确认项目的时候的预设时间值
export const ORDERTYPE = { 1:'预约项目订单', 2: '耗材订单' }; // 订单类型
export const PRODEVIDETYPE = { 1: '优惠券', 2: '现金券' }; // 优惠券类型
/**
 * 系统配置
 * @type couponDesc: 优惠券设置时的描述 links->activity/share/index
 * agreement 协议
 */
export const BIZCONFIG = { couponDesc: 'other-settings/nurse-coupon-desc',
  agreement: 'other-settings/nurse-agreement' };
// export const PAYSTATUS = { 0: '待支付', 5: '已退款' };
export const GOODSORDERSTATUS = { // 耗材订单状态 , color 代表可能的文字显示颜色, 不改色就置空
  0: {
    color:'#FF3030',
    text:'待支付'
  },
  3:{
    color:'#4E8BE7',
    text:'已支付'
  },
  4: {
    color: '#ff976a',
    text: '已取消'
  },
  10: {
    color: '#ff976a',
    text: '已作废'
  },
};

export const RESIDENCEFORM = { 1: '与家人同居', 2: '独居', 3: '养老机构', 4: '其他' };
export const GARBADGETYPE = { // 废弃物种类
  wasteChemical: '化学性废弃物',
  wasteDrug: '药物性废弃物',
  wasteInfectivity: '感染性废弃物',
  wasteInjury: '损伤性废弃物',
  wastePathologic: '病理性废弃物',
}

export default {
  sex:{ 1:'男', 2:'女', 9:'未知' },
  bloodSugar,
  ORDERTYPE,
  pulse,
  bloodPressure,
  DATERANGE,
  DATEMAPPER,
  temperature:temperatureConst,
  SUGGESTTAG,
  AGEFIELD,
  TIMERANGE,
  GOODSORDERSTATUS,
  TIMEDETAILS,
  RESIDENCEFORM,
  IDCARDREG,
  NURSETITLELEVEL,
  PRODEVIDETYPE,
  BIZCONFIG,
  GARBADGETYPE
};
