const state = {
  statusBar:{
    show:false,
    height:'0px',
    title:''
  },
};
const getters = {
  statusBar: (state) => state.statusBar,
};
const mutations = {
  hideStatusBar(state) {
    state.statusBar = {
      ...state.statusBar,
      show:false,
      title: ''
    };
  },
  setStatusBar(state, { title }) {
    state.statusBar = {
      ...state.statusBar,
      show: true,
      title
    };
  },
  setStatusBarHeight(state, value) {
    state.statusBar = {
      ...state.statusBar,
      height: value,
    };
  }
};
const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
