<template>
  <div>
    <router-view />
    <div
      v-if="showFooter"
      style="height: 50px"
    />

    <Tabbar
      v-if="showFooter"
      v-model="active"
      route
    >
      <TabbarItem
        icon="wap-home-o"
        :to="{name:'cureView'}"
      >
        首页
      </TabbarItem>
      <TabbarItem
        icon="play-circle-o"
        :to="{name:'videoHomeView'}"
      >
        视频学习
      </TabbarItem>
      <TabbarItem
        icon="friends-o"
        :to="{name:'centerView'}"
      >
        我的
      </TabbarItem>
    </Tabbar>
  </div>
</template>
<script>
import { Tabbar, TabbarItem } from 'vant';
import { setLocalStorage } from '../utils/storage';

export default {
  components: { Tabbar, TabbarItem },
  data() {
    return {
      active: 0,
      showFooter: true
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (to?.meta?.showFooter) {
      this.showFooter = true;
    } else {
      this.showFooter = false;
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to?.meta?.showFooter) {
        vm.showFooter = true;
      } else {
        vm.showFooter = false;
      }
    });
  }
};
</script>
<style lang="less" scoped>
  .full_height{
    width: 100%;
    height: 100%;
  }
</style>
